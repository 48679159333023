import axios from "axios";
import { Url } from "../types/url";
export const addItem = (item) => {
  return async (dispatch) => {
    dispatch({ type: "ADD_ITEM_START" });

    let config = {
      method: "post",
      url: Url + "/api/order",
      headers: {
        "Content-Type": "application/json",
      },
      maxRedirects: 0,
      data: JSON.stringify(item),
    };

    try {
      const response = await axios.request(config);
      dispatch({ type: "ADD_ITEM_SUCCESS", payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: "ADD_ITEM_ERROR", payload: error.message });
      throw error;
    }
  };
};

export const addComments = (item) => {
  return async (dispatch) => {
    dispatch({ type: "ADD_ITEM_START" });

    let config = {
      method: "post",
      url: Url + "/api/comment",
      headers: {
        "Content-Type": "text/plain",
      },
      maxRedirects: 0,
      data: JSON.stringify(item),
    };

    try {
      const response = await axios.request(config);
      dispatch({ type: "ADD_ITEM_SUCCESS", payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: "ADD_ITEM_ERROR", payload: error.message });
      throw error;
    }
  };
};

export const addContact = (item) => {
  return async (dispatch) => {
    dispatch({ type: "ADD_CONTACT_START" });

    let config = {
      method: "post",
      url: Url + "/api/yorumekle",
      headers: {
        "Content-Type": "text/plain",
      },
      maxRedirects: 0,
      data: JSON.stringify(item),
    };

    try {
      const response = await axios.request(config);
      dispatch({ type: "ADD_CONTACT_SUCCESS", payload: response.data });
      return response;
    } catch (error) {
      dispatch({ type: "ADD_CONTACT_ERROR", payload: error.message });
      throw error;
    }
  };
};
