// actions.js
import axios from "axios";
import { Url } from "../types/url";

export const getProjects = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_PROJECTS_START" });
    try {
      const res = await axios.get(Url + "/api/products");
      dispatch({ type: "GET_PROJECTS_SUCCESS", payload: res.data });
    } catch (error) {
      dispatch({ type: "GET_PROJECTS_ERROR", payload: error.message });
    }
  };
};

export const getCategories = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_CATEGORIES_START" });
    try {
      const res = await axios.get(Url + "/api/categories");
      dispatch({ type: "GET_CATEGORIES_SUCCESS", payload: res.data });
    } catch (error) {
      dispatch({ type: "GET_CATEGORIES_ERROR", payload: error.message });
    }
  };
};

export const getNews = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_NEWS_START" });
    try {
      const res = await axios.get(Url + "/api/news");
      dispatch({ type: "GET_NEWS_SUCCESS", payload: res.data });
    } catch (error) {
      dispatch({ type: "GET_NEWS_ERROR", payload: error.message });
    }
  };
};

// export const getPrice = () => {
//   return async (dispatch) => {
//     dispatch({ type: "GET_PRICE_START" });
//     try {
//       const res = await axios.get(
//         "https://api.currencyfreaks.com/v2.0/rates/latest?apikey=f46b5d7abd714cd284838bcfb7dd1e7a"
//       );
//       dispatch({ type: "GET_PRICE_SUCCESS", payload: res.data.rates });
//     } catch (error) {
//       dispatch({ type: "GET_PRICE_ERROR", payload: error.message });
//     }
//   };
// };
