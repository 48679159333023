import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

function Projects() {
  const { projects } = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };
  return (
    <section className="container pb-5 mt-md-n2 mb-2 mb-sm-3 mb-md-4 mb-xl-5">
      {/* Heading */}
      <div className="d-flex align-items-center justify-content-between border-bottom pb-3 pb-md-4">
        <h2 className="h3 mb-0"> المنتجات </h2>
        <div className="nav ms-3">
          <Link
            onClick={() => {
              scrollToTop();
            }}
            to={"/products"}
            className="nav-link animate-underline px-0 py-2"
          >
            <span className="animate-target"> عرض الكل </span>
            <i className="ci-chevron-right fs-base ms-1" />
          </Link>
        </div>
      </div>
      {/* Product grid */}
      <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 mt-3">
        {/* Item */}
        {projects.slice(0, 6).map((item, key) => (
          <div key={key} className="col">
            <div className=" h-100 animate-underline">
              <Link
                onClick={() => {
                  scrollToTop();
                }}
                to={`/info_product/${item.id}`}
                className="hover-effect-opacity ratio ratio-1x1 d-block mb-3"
              >
                <img
                  src={`https://top-decor.alayadi.site/product_imgs/${item.images[0].product_photo}`}
                  className="hover-effect-target opacity-100"
                  alt="Product"
                />
                <img
                  src={`https://top-decor.alayadi.site/product_imgs/${item.images[0].product_photo}`}
                  className="position-absolute top-0 start-0 hover-effect-target opacity-0 rounded-4"
                  alt="Room"
                />
              </Link>
              <div className="d-flex gap-2 mb-3">
                <input
                  type="radio"
                  className="btn-check"
                  name="colors-4"
                  id="color-4-1"
                  defaultChecked
                />
                <label
                  htmlFor="color-4-1"
                  className="btn btn-color fs-base"
                  style={{ color: "#384043" }}
                >
                  <span className="visually-hidden">Dark gray</span>
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="colors-4"
                  id="color-4-2"
                />
                <label
                  htmlFor="color-4-2"
                  className="btn btn-color fs-base"
                  style={{ color: "#bdc5da" }}
                >
                  <span className="visually-hidden">Light gray</span>
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  name="colors-4"
                  id="color-4-3"
                />
                <label
                  htmlFor="color-4-3"
                  className="btn btn-color fs-base"
                  style={{ color: "#526f99" }}
                >
                  <span className="visually-hidden">Bluish gray</span>
                </label>
              </div>
              <h3 className="mb-2">
                <Link
                  onClick={() => {
                    scrollToTop();
                  }}
                  to={`/info_product/${item.id}`}
                  className="d-block fs-sm fw-medium text-truncate"
                >
                  <span className="animate-target">
                    {item.product_title_ar}
                  </span>
                </Link>
              </h3>
              <div className="h6">{item.product_price} د.ع </div>
              <div className=" d-flex gap-2">
                <a
                  target="_blank"
                  href={`https://api.whatsapp.com/send?phone=+9647717111981&text=مرحبًا%0Aأنا أود شراء المنتج ${item.product_title_ar}%0Aرمز المنتج: ${item.product_code}%0Aالسعر: ${item.product_price}%0Aيرجى تقديم المزيد من المعلومات حول المنتج وكيفية الشراء.%0Aشكرًا.`}
                  data-action="share/whatsapp/share"
                  type="button"
                  className="btn btn-dark w-100 rounded-pill px-3"
                >
                  طلب سريع
                </a>
                <button
                  onClick={() => {
                    dispatch({
                      type: "add",
                      id: item.id,
                      colorId: 2,
                    });
                  }}
                  type="button"
                  className="btn btn-secondary   rounded-pill animate-pulse"
                  aria-label="Add to wishlist"
                >
                  <i className="ci-shopping-cart animate-target" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Projects;
