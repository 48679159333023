import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Navigation,
  EffectCoverflow,
  EffectFade,
  Autoplay,
} from "swiper/modules";
import Img1 from "../../images/Screenshot 2024-06-29 183136.png";
import Img2 from "../../images/Screenshot 2024-06-29 184104.png";
import Img3 from "../../images/Screenshot 2024-06-29 184847.png";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

function Header() {
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };
  return (
    <section
      className="bg-body-tertiary min-vh-100 d-flex align-items-center overflow-hidden"
      style={{ marginTop: "-110px", paddingTop: "110px" }}
    >
      <div className="container h-100 py-5 my-md-2 my-lg-3 my-xl-4 mb-xxl-5">
        <h2
          className="display-5 text-center mx-auto mb-4"
          style={{ maxWidth: "680px" }}
        >
          مرحبا بكم في شركة توب ديكور
        </h2>

        <p className=" text-center mb-4">
          نحن هنا لنجعل منازل وأماكن عملكم أماكن أكثر جمالًا وراحة.{" "}
        </p>
        <div className="row align-items-center justify-content-center gx-3 gx-sm-4 mb-3 mb-sm-4">
          <div className="col-auto col-sm-1 order-1 order-lg-2 d-flex align-items-center justify-content-center">
            <button
              aria-label="Prev"
              className="btn-prev btn btn-lg btn-icon btn-outline-secondary rounded-circle animate-slide-start"
              type="button"
            >
              <i className="ci-chevron-left fs-xl animate-target" />
            </button>
          </div>
          <div className="col-sm-10 col-lg-8 col-xl-6 order-3">
            <Swiper
              className="swiper user-select-none rounded-pill"
              loop={true}
              grabCursor={true}
              speed={600}
              effect="coverflow"
              coverflowEffect={{
                rotate: 0,
                scale: 1.3,
                depth: -200,
                stretch: -100,
                slideShadows: false,
              }}
              autoplay={{ delay: 3000 }}
              navigation={{
                prevEl: ".btn-prev",
                nextEl: ".btn-next",
              }}
              modules={[EffectCoverflow, Navigation, EffectFade, Autoplay]}
            >
              <SwiperSlide className="swiper-slide">
                <div
                  className="ratio"
                  style={{ "--cz-aspect-ratio": "calc(400 / 636 * 100%)" }}
                >
                  <img alt="Image" src={Img1} />
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div
                  className="ratio"
                  style={{ "--cz-aspect-ratio": "calc(400 / 636 * 100%)" }}
                >
                  <img alt="Image" src={Img2} />
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div
                  className="ratio"
                  style={{ "--cz-aspect-ratio": "calc(400 / 636 * 100%)" }}
                >
                  <img alt="Image" src={Img3} />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="col-auto col-sm-1 order-2 order-sm-3 order-lg-4 d-flex align-items-center justify-content-center">
            <button
              aria-label="Next"
              className="btn-next btn btn-lg btn-icon btn-outline-secondary rounded-circle animate-slide-end"
              type="button"
            >
              <i className="ci-chevron-right fs-xl animate-target" />
            </button>
          </div>
        </div>

        <div className=" bg-body-tertiary text-center">
          <h3 className="text-secondary-emphasis fs-base fw-normal mb-2">
            شاهد جميع منتجاتنا
          </h3>
          <Link
            onClick={() => {
              scrollToTop();
            }}
            to={"/products"}
            className="btn btn-lg btn-dark rounded-pill"
          >
            تسوق الأن
            <i className="ci-chevron-right fs-lg ms-2 me-n2" />
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Header;
