const initialState = [];

function cartReducer(state = initialState, action) {
  switch (action.type) {
    case "add":
      const itemInCart = state.find(
        (item) => item.id === action.id && item.colorId === action.colorId
      );
      if (itemInCart) {
        return state.map((item) =>
          item.id === action.id && item.colorId === action.colorId
            ? { ...item, count: item.count + 1 }
            : item
        );
      } else {
        return [...state, { id: action.id, colorId: action.colorId, count: 1 }];
      }
    case "remove":
      const itemToRemove = state.find(
        (item) => item.id === action.id && item.colorId === action.colorId
      );
      if (itemToRemove) {
        if (itemToRemove.count > 1) {
          return state.map((item) =>
            item.id === action.id && item.colorId === action.colorId
              ? { ...item, count: item.count - 1 }
              : item
          );
        } else {
          return state.filter(
            (item) => item.id !== action.id || item.colorId !== action.colorId
          );
        }
      } else {
        return state;
      }
    case "delete":
      return state.filter(
        (item) => !(item.id === action.id && item.colorId === action.colorId)
      );
    case "clearCart":
      return [];
    default:
      return state;
  }
}

export default cartReducer;
