import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { animateScroll as scroll } from "react-scroll";
import { useSelector } from "react-redux";

const Footer = () => {
  const { categories } = useSelector((state) => state.categories);
  const { t } = useTranslation("global");
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };
  return (
    <footer className="footer bg-dark pb-4 py-lg-5" data-bs-theme="dark">
      <div className="container pt-5 pt-lg-4 mt-sm-2 mt-md-3">
        <div className="row pb-5">
          <div className="col-md col-xl-8 order-md-2">
            <div
              className="text-center px-sm-4 mx-auto"
              style={{
                maxWidth: "568px",
              }}
            >
              <h5 className="pb-1 mb-2">تابعونا على وسائل التواصل الاجتماعي</h5>

              <div className="d-flex justify-content-center gap-2 pt-4 pt-md-5 mt-1 mt-md-0">
                <a
                  href="https://api.whatsapp.com/send?phone=+9647717111981&text=مرحبا "
                  target="_blank"
                  data-action="share/whatsapp/share"
                  aria-label="Follow us on whatsapp"
                  className="btn btn-icon fs-base btn-outline-secondary border-0"
                  data-bs-template='<div class="tooltip fs-xs mb-n2" role="tooltip"><div class="tooltip-inner bg-transparent text-white p-0"></div></div>'
                  data-bs-toggle="tooltip"
                  title="whatsapp"
                >
                  <i className="ci-whatsapp" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61559510288702&mibextid=LQQJ4d"
                  aria-label="Follow us on Facebook"
                  className="btn btn-icon fs-base btn-outline-secondary border-0"
                  data-bs-template='<div class="tooltip fs-xs mb-n2" role="tooltip"><div class="tooltip-inner bg-transparent text-white p-0"></div></div>'
                  data-bs-toggle="tooltip"
                  title="Facebook"
                >
                  <i className="ci-facebook" />
                </a>
                <a
                  href="https://www.instagram.com/top.decor.tk?igsh=Znl1dHh4dW4zeWQ4"
                  aria-label="Follow us on Instagram"
                  className="btn btn-icon fs-base btn-outline-secondary border-0"
                  data-bs-template='<div class="tooltip fs-xs mb-n2" role="tooltip"><div class="tooltip-inner bg-transparent text-white p-0"></div></div>'
                  data-bs-toggle="tooltip"
                  title="Instagram"
                >
                  <i className="ci-instagram" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-auto col-xl-2 text-center order-md-1 pt-4 pt-md-0">
            <ul className="nav d-inline-flex flex-md-column justify-content-center align-items-center gap-md-2">
              {categories.map((item, key) => (
                <li key={key} className="animate-underline my-1 mx-2 m-md-0">
                  <a className="nav-link d-inline-flex fw-normal p-0 animate-target">
                    {item.categori_name_ar}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-auto col-xl-2 text-center order-md-3 pt-3 pt-md-0">
            <ul className="nav d-inline-flex flex-md-column justify-content-center align-items-center gap-md-2">
              <li className="animate-underline my-1 mx-2 m-md-0">
                <Link
                  to={"./"}
                  className="nav-link d-inline-flex fw-normal p-0 animate-target"
                >
                  الرئيسية
                </Link>
              </li>
              <li className="animate-underline my-1 mx-2 m-md-0">
                <Link
                  to={"/products"}
                  className="nav-link d-inline-flex fw-normal p-0 animate-target"
                >
                  المنتجات
                </Link>
              </li>
              <li className="animate-underline my-1 mx-2 m-md-0">
                <Link
                  to={"/about"}
                  className="nav-link d-inline-flex fw-normal p-0 animate-target"
                >
                  من نحن
                </Link>
              </li>
              <li className="animate-underline my-1 mx-2 m-md-0">
                <Link
                  to={"/contact"}
                  className="nav-link d-inline-flex fw-normal p-0 animate-target"
                >
                  اتصل بنا
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <p className="fs-xs text-body text-center pt-lg-4 mt-n2 mt-md-0 mb-0">
          © 2024, All rights reserved,{" "}
          <span className="animate-underline">
            <a
              className="animate-target text-white text-decoration-none"
              href="https://alayadids.com/"
              rel="alayadids.com"
              target="_blank"
            >
              Alayadi Digital Solutions.
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
