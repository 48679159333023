import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Navigation,
  EffectCoverflow,
  EffectFade,
  Autoplay,
} from "swiper/modules";
function InfoProduct() {
  const { projects } = useSelector((state) => state.projects);
  const { id } = useParams();
  const dispatch = useDispatch();
  const InfoData = projects.filter((i) => i.id == id);
  console.log(InfoData);
  return (
    <main className="content-wrapper">
      <div className="container">
        <nav
          aria-label="breadcrumb"
          className="position-relative pt-3 mt-3 mt-md-4 mb-4"
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"} href="home-furniture.html">
                الرئيسية
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/products"} href="shop-catalog-furniture.html">
                المنتجات
              </Link>
            </li>
            <li aria-current="page" className="breadcrumb-item active">
              تفاصيل المنتج
            </li>
          </ol>
        </nav>
        {InfoData.map((item, key) => {
          const Image = item.images;
          return (
            <section
              key={key}
              className="row pb-4 pb-md-5 mb-2 mb-md-0 mb-xl-3"
            >
              <div className="col-md-7 col-xl-8 pb-4 pb-md-0 mb-2 mb-sm-3 mb-md-0">
                <div className="row row-cols-12 g-3 g-sm-4 g-md-3 g-lg-4">
                  {Image.map((img, key) => (
                    <Swiper
                      loop={true}
                      grabCursor={true}
                      speed={600}
                      effect="coverflow"
                      coverflowEffect={{
                        rotate: 0,
                        scale: 1.3,
                        depth: -200,
                        stretch: -100,
                        slideShadows: false,
                      }}
                      navigation={{
                        prevEl: ".btn-prev",
                        nextEl: ".btn-next",
                      }}
                      modules={[EffectCoverflow, Navigation, EffectFade]}
                      key={key}
                      className="col"
                    >
                      <SwiperSlide className="ratio ratio-1x1 hover-effect-target">
                        <img
                          alt="Image"
                          src={`https://top-decor.alayadi.site/product_imgs/${img.product_photo}`}
                        />
                      </SwiperSlide>
                    </Swiper>
                  ))}
                </div>
              </div>
              <div className="col-md-5 col-xl-4">
                <div
                  className="d-none d-md-block"
                  style={{
                    marginTop: "-90px",
                  }}
                />
                <div className="sticky-md-top ps-md-2 ps-xl-4">
                  <div
                    className="d-none d-md-block"
                    style={{
                      paddingTop: "90px",
                    }}
                  />
                  <div className="fs-xs text-body-secondary mb-3">
                    {item.product_code}
                  </div>
                  <h1 className="fs-xl fw-medium">{item.product_title_ar}</h1>
                  <div className="h4 fw-bold mb-4">
                    {item.product_price} د.ع
                    {/* <del className="fs-sm fw-normal text-body-tertiary">
                      $416.00
                    </del> */}
                  </div>
                  <div className=" mb-4">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.product_description_ar,
                      }}
                    ></p>
                  </div>
                  <div className="d-flex gap-3 pb-4 mb-1 mt-4 mb-lg-3">
                    <a
                      target="_blank"
                      href={`https://api.whatsapp.com/send?phone=+9647717111981&text=مرحبًا%0Aأنا أود شراء المنتج ${item.product_title_ar}%0Aرمز المنتج: ${item.product_code}%0Aالسعر: ${item.product_price}%0Aيرجى تقديم المزيد من المعلومات حول المنتج وكيفية الشراء.%0Aشكرًا.`}
                      data-action="share/whatsapp/share"
                      type="button"
                      className="btn btn-dark w-100 rounded-pill px-3"
                    >
                      طلب سريع
                    </a>
                    <button
                      onClick={() => {
                        dispatch({
                          type: "add",
                          id: item.id,
                          colorId: 2,
                        });
                      }}
                      aria-label="Add to Wishlist"
                      className="btn btn-icon btn-lg btn-secondary rounded-circle animate-pulse"
                      type="button"
                    >
                      <i className="ci-shopping-cart animate-target" />
                    </button>
                  </div>
                  <div className="col-md-12 col-xl-12 mb-xxl-12">
                    <ul dir="rtl" className="list-unstyled pb-md-2 pb-lg-3">
                      <li className="mt-1">
                        <span className="h6 mb-0">قياس:</span> {item.measure}
                      </li>
                      <li className="mt-1">
                        <span className="h6 mb-0">وزن:</span> {item.weight}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          );
        })}
      </div>
    </main>
  );
}

export default InfoProduct;
