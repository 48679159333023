import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjects,
  getCategories,
  getNews,
} from "./context/actions/getaction";
import Navbar from "./components/layouts/Navbar";
import Footer from "./components/layouts/Footer";
import Home from "./components/pages/home";
import About from "./components/pages/about";
import Contact from "./components/pages/contact";
import Property from "./components/pages/projects";
import "./index.css";
import { animateScroll as scroll } from "react-scroll";
import InfoProduct from "./components/pages/projects/InfoProduct";
import Confirmed from "./components/layouts/Confirmed";

function App() {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.projects);

  useEffect(() => {
    dispatch(getProjects());
    dispatch(getCategories());
    // dispatch(getNews());
  }, [dispatch]);

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };

  return (
    <div>
      <BrowserRouter>
        <Navbar />
        {/* حالة الانتظار */}
        {loading && (
          <div className="text-center my-5">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {/* حالة الخطأ */}
        {error && (
          <div className="alert alert-danger text-center my-5" role="alert">
            {error}
          </div>
        )}
        {/* عرض المحتوى فقط عندما لا يكون هناك تحميل أو خطأ */}
        {!loading && !error && (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<Property />} />
            <Route path="/info_product/:id" element={<InfoProduct />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/confirmed/:code" element={<Confirmed />} />
          </Routes>
        )}
        <Footer />
      </BrowserRouter>
      <>
        {/* Back to top button */}
        <div className="floating-buttons position-fixed top-50 end-0 z-sticky me-3 me-xl-4 pb-4">
          <a
            className="btn-scroll-top btn btn-sm bg-body border-0 rounded-pill shadow animate-slide-end"
            href="#top"
          >
            Top
            <i className="ci-arrow-right fs-base ms-1 me-n1 animate-target" />
            <span className="position-absolute top-0 start-0 w-100 h-100 border rounded-pill z-0" />
            <svg
              className="position-absolute top-0 start-0 w-100 h-100 z-1"
              viewBox="0 0 62 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x=".75"
                y=".75"
                width="60.5"
                height="30.5"
                rx="15.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit={10}
              />
            </svg>
          </a>
        </div>
      </>
    </div>
  );
}

export default App;
