import React from "react";
import { useSelector } from "react-redux";

function Categories() {
  const { categories } = useSelector((state) => state.categories);
  return (
    <section className="container py-5 my-2 my-sm-3 mb-md-2 mt-lg-4 my-xl-5">
      <div
        className="overflow-x-auto pt-xxl-3"
        data-simplebar=""
        data-simplebar-auto-hide="false"
      >
        <div className="row flex-nowrap flex-md-wrap justify-content-md-center g-0 gap-4 gap-md-0">
          {/* Category */}
          {categories.map((item, key) => (
            <div key={key} className="col col-md-4 col-lg-3 col-xl-2 mb-4">
              <div
                className="category-card w-100 text-center px-1 px-lg-2 px-xxl-3 mx-auto"
                style={{ minWidth: 165 }}
              >
                <div className="category-card-body">
                  <a
                    className="d-block text-decoration-none"
                    href="shop-catalog-furniture.html"
                  >
                    <div
                      className="bg-body-tertiary rounded-pill mb-3 mx-auto"
                      style={{ maxWidth: 164 }}
                    >
                      <div className="ratio ratio-1x1">
                        <img
                          src={`https://top-decor.alayadi.site/categori_imgs/${item.categori_photo}`}
                          className="rounded-pill"
                          alt="Image"
                        />
                      </div>
                    </div>
                    <h3 className="category-card-title h6 text-truncate">
                      {item.categori_name_ar}
                    </h3>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Categories;
