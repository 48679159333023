import React from "react";
import Header from "./Header";
import Categories from "./Categories";
import Projects from "./Projects";
// استهدف جميع عناصر التحكم بناءً على الفئة
const swiperControls = document.querySelectorAll(".swiper-control");

// اضبط العناصر التحكم لكل Swiper
swiperControls.forEach((control) => {
  control.addEventListener("click", () => {
    // احصل على Swiper المناسب باستخدام الخيارات أو الفئة أو أي طريقة تحديد أخرى
    const swiperInstances = document.querySelectorAll(".swiper");
    swiperInstances.forEach((swiper) => {
      swiper.swiperButtonPrev.click(); // اضبط الأمر المناسب للانتقال إلى الشريحة السابقة
      swiper.swiperButtonNext.click(); // اضبط الأمر المناسب للانتقال إلى الشريحة التالية
    });
  });
});

const Home = () => {
  return (
    <main className="content-wrapper">
      <Header />
      <Categories />
      <Projects />
    </main>
  );
};

export default Home;
