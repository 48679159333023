import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import Logo from "../images/logo.png";
import ShopingCard from "./ShopingCard";
import { useSelector } from "react-redux";

const Navbar = () => {
  const location = useLocation();
  const { pathname } = location;
  const cart = useSelector((state) => state.cart);
  const totalItems = cart.reduce((total, item) => total + item.count, 0);

  console.log(cart);
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };

  return (
    <>
      <ShopingCard />
      <div className="container position-relative d-flex justify-content-between z-1 py-3">
        <div className="nav animate-underline">
          <span className="text-secondary-emphasis fs-xs me-1">
            أتصل بنا <span className="d-none d-sm-inline">24/7</span>
          </span>
          <a
            href="tel:+9647838111981"
            className="nav-link animate-target fs-xs fw-semibold p-0"
          >
            +964 783 811 1981
          </a>
        </div>
      </div>
      <header
        className="navbar-sticky sticky-top container z-fixed px-2"
        data-sticky-element=""
      >
        <div className="navbar navbar-expand-lg flex-nowrap bg-body rounded-pill shadow ps-0 mx-1">
          <div className="position-absolute top-0 start-0 w-100 h-100 bg-dark rounded-pill z-0 d-none d-block-dark" />
          <button
            aria-controls="navbarNav"
            aria-label="Toggle navigation"
            className="navbar-toggler ms-3"
            data-bs-target="#navbarNav"
            data-bs-toggle="offcanvas"
            type="button"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <Link
            to={"/"}
            onClick={() => {
              scrollToTop();
            }}
            className="navbar-brand position-relative z-1 ms-4 ms-sm-5 ms-lg-4 me-2 me-sm-0 me-lg-3"
          >
            <img
              style={{ width: "65px", height: "10%" }}
              className=""
              src={Logo}
              alt="Logo"
            />
          </Link>
          <nav
            aria-labelledby="navbarNavLabel"
            className="offcanvas offcanvas-start"
            id="navbarNav"
            tabIndex="-1"
          >
            <div className="offcanvas-header py-3 justify-content-between">
              <img
                style={{ width: "30%", height: "100%" }}
                className=""
                src={Logo}
                alt="Logo"
              />
              <button
                aria-label="Close"
                className="btn"
                data-bs-dismiss="offcanvas"
                type="button"
              >
                <i className="ci-close" />
              </button>
            </div>
            <div className="offcanvas-body pt-3 pb-4 py-lg-0 mx-lg-auto">
              <ul className="navbar-nav position-relative">
                <li className="nav-item  me-lg-n1 me-xl-0">
                  <Link
                    to={"/"}
                    aria-current="page"
                    aria-expanded="false"
                    className={`nav-link fs-sm ${
                      pathname === "/" ? "active" : ""
                    } `}
                    data-bs-trigger="hover"
                    role="button"
                  >
                    الرئيسية
                  </Link>
                </li>
                <li className="nav-item  position-static me-lg-n1 me-xl-0">
                  <Link
                    aria-expanded="false"
                    className={`nav-link  fs-sm ${
                      pathname === "/projects" ? "active" : ""
                    }`}
                    data-bs-trigger="hover"
                    to={"/products"}
                    role="button"
                  >
                    المنتجات
                  </Link>
                </li>
                <li className="nav-item  me-lg-n1 me-xl-0">
                  <Link
                    to={"/about"}
                    aria-expanded="false"
                    className={`nav-link  fs-sm ${
                      pathname === "/about" ? "active" : ""
                    }`}
                    data-bs-auto-close="outside"
                    data-bs-trigger="hover"
                    role="button"
                  >
                    من نحن
                  </Link>
                </li>
                <li className="nav-item  me-lg-n1 me-xl-0">
                  <Link
                    to={"/contact"}
                    aria-expanded="false"
                    className={`nav-link  fs-sm ${
                      pathname === "/contact" ? "active" : ""
                    }`}
                    data-bs-auto-close="outside"
                    data-bs-trigger="hover"
                    role="button"
                  >
                    اتصل بنا
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <div className="d-flex gap-sm-1 position-relative z-1">
            <button
              aria-controls="shoppingCart"
              aria-label="Shopping cart"
              className="btn btn-icon fs-lg btn-outline-secondary border-0 rounded-circle animate-scale me-2 position-relative"
              data-bs-target="#shoppingCart"
              data-bs-toggle="offcanvas"
              type="button"
            >
              {totalItems > 0 ? (
                <div className="badge mb-4">{totalItems}</div>
              ) : null}
              <i className="ci-shopping-cart animate-target" />
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
