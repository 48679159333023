import React from "react";
import { Link, useParams } from "react-router-dom";

function Confirmed() {
  const { code } = useParams();
  return (
    <section className="container pt-3 pt-sm-4 pb-5 mb-2 mb-md-3 mb-lg-4 mb-xl-5">
      <div
        className="position-relative overflow-hidden rounded-5 p-4 p-sm-5"
        style={{ backgroundColor: "var(--cz-success-border-subtle)" }}
      >
        <div
          dir="rtl"
          className="position-relative z-2 text-center py-4 py-md-5 my-md-2 my-lg-5 mx-auto"
          style={{ maxWidth: 536 }}
        >
          <h1 className="pt-xl-4 mb-4">شكرًا لطلبك!</h1>
          <p className="text-dark-emphasis pb-3 pb-sm-4">
            تم قبول طلبك <span className="fw-semibold">{code}</span> وسيتم
            معالجته قريبًا. توقع وصول مندوبنا{" "}
          </p>
          <Link
            to={"/products"}
            className="btn btn-lg btn-dark rounded-pill mb-xl-4"
          >
            متابعة التسوق
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Confirmed;
