import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { useTranslation } from "react-i18next";
import "./style.css";
function Property() {
  const { t } = useTranslation("global");
  const { projects } = useSelector((state) => state.projects);
  const { categories } = useSelector((state) => state.categories);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const [slice, setSlice] = useState(14);
  const [display, setDisplay] = useState("");
  const filteridData = projects.filter((i) =>
    filter ? i.categories_id == filter : projects
  );
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };

  return (
    <main className="content-wrapper">
      <div className="container  pb-5 mb-2 mb-sm-3 mb-lg-4 mb-xl-5">
        <nav
          aria-label="breadcrumb"
          className="position-relative pt-3 my-3 my-md-4"
          style={{
            zIndex: "1021",
          }}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"}>الرئيسية</Link>
            </li>
            <li aria-current="page" className="breadcrumb-item active">
              المنتجات
            </li>
          </ol>
        </nav>
        <h1
          className="h3 text-center position-relative pb-sm-2 pb-md-3"
          style={{
            zIndex: "1021",
          }}
        >
          المنتجات
        </h1>
        <div
          dir="rtl"
          className="sticky-top bg-body mb-3 mt-1 mb-sm-4"
          style={{
            marginTop: "-4.5rem",
          }}
        >
          <div className="row align-items-center pt-5">
            <div className="col-12 col-sm-12 col-md-12 mt-2">
              <ul
                className="nav nav-underline flex-nowrap justify-content-start overflow-auto"
                id="viewShwitcher"
                style={{ whiteSpace: "nowrap" }}
              >
                {categories.map((item, key) => (
                  <li
                    key={key}
                    className="nav-item d-inline-block mb-1"
                    role="presentation"
                  >
                    <button
                      onClick={() => {
                        setFilter(item.id);
                      }}
                      aria-selected="true"
                      className={`nav-link ${
                        filter == item.id ? "active" : ""
                      }`}
                      id="showProduct"
                      role="tab"
                      type="button"
                    >
                      {item.categori_name_ar}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div
          className="row row-cols-2 row-cols-md-3 row-cols-lg-4 gy-5"
          id="productGrid"
        >
          {filteridData.slice(0, slice).map((item, key) => {
            return (
              <div key={key} className="col">
                <div className="animate-underline mb-sm-2">
                  <Link
                    to={`/info_product/${item.id}`}
                    onClick={() => {
                      scrollToTop();
                    }}
                    className="hover-effect-opacity ratio ratio-1x1 d-block mb-3"
                  >
                    <img
                      alt="Product"
                      className="hover-effect-target opacity-100"
                      src={`https://top-decor.alayadi.site/product_imgs/${item.images[0].product_photo}`}
                    />
                    <img
                      alt="Room"
                      className="position-absolute top-0 start-0 hover-effect-target opacity-0 rounded-4"
                      src={`https://top-decor.alayadi.site/product_imgs/${item.images[0].product_photo}`}
                    />
                  </Link>
                  {/* <div className="d-flex gap-2 mb-3">
                    <input
                      className="btn-check"
                      defaultChecked
                      id="color-1-1"
                      name="colors-1"
                      type="radio"
                    />
                    <label
                      className="btn btn-color fs-base"
                      htmlFor="color-1-1"
                      style={{
                        color: "#32808e",
                      }}
                    >
                      <span className="visually-hidden">Emerald</span>
                    </label>
                    <input
                      className="btn-check"
                      id="color-1-2"
                      name="colors-1"
                      type="radio"
                    />
                    <label
                      className="btn btn-color fs-base"
                      htmlFor="color-1-2"
                      style={{
                        color: "#767e93",
                      }}
                    >
                      <span className="visually-hidden">Bluish gray</span>
                    </label>
                    <input
                      className="btn-check"
                      id="color-1-3"
                      name="colors-1"
                      type="radio"
                    />
                    <label
                      className="btn btn-color fs-base"
                      htmlFor="color-1-3"
                      style={{
                        color: "#cd8d01",
                      }}
                    >
                      <span className="visually-hidden">Mustard</span>
                    </label>
                  </div> */}
                  <h3 className="mb-2">
                    <Link
                      to={`/info_product/${item.id}`}
                      onClick={() => {
                        scrollToTop();
                      }}
                      className="d-block fs-sm fw-medium text-truncate"
                    >
                      <span className="animate-target">
                        {item.product_title_ar}
                      </span>
                    </Link>
                  </h3>
                  <div className="h6">{item.product_price} د.ع </div>
                  <div className=" d-flex gap-2">
                    <a
                      target="_blank"
                      href={`https://api.whatsapp.com/send?phone=+9647717111981&text=مرحبًا%0Aأنا أود شراء المنتج ${item.product_title_ar}%0Aرمز المنتج: ${item.product_code}%0Aالسعر: ${item.product_price}%0Aيرجى تقديم المزيد من المعلومات حول المنتج وكيفية الشراء.%0Aشكرًا.`}
                      data-action="share/whatsapp/share"
                      type="button"
                      className="btn btn-dark w-100 rounded-pill px-3"
                    >
                      طلب سريع
                    </a>
                    <button
                      onClick={() => {
                        dispatch({
                          type: "add",
                          id: item.id,
                          colorId: 2,
                        });
                      }}
                      type="button"
                      className="btn btn-secondary   rounded-pill animate-pulse"
                      aria-label="Add to wishlist"
                    >
                      <i className="ci-shopping-cart animate-target" />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={`text-center pt-5 mt-md-2 mt-lg-3 mt-xl-4 mb-xxl-3 mx-auto ${display}`}
          style={{
            maxWidth: "306px",
          }}
        >
          <p className="fs-sm">
            عرض {filteridData.length < 15 ? filteridData.length : slice} من{" "}
            {filteridData.length}
          </p>
          <div
            aria-label="Items shown"
            className="progress mb-3"
            role="progressbar"
            style={{
              height: "4px",
            }}
          >
            <div
              className="progress-bar bg-dark rounded-pill d-none-dark"
              style={{
                width: "25%",
              }}
            />
            <div
              className="progress-bar bg-light rounded-pill d-none d-block-dark"
              style={{
                width: "25%",
              }}
            />
          </div>
          <div className="nav justify-content-center">
            <a
              className="nav-link animate-underline fs-base pt-2 pb-0 px-0"
              onClick={() => {
                setSlice(projects.length);
                setDisplay("d-none");
              }}
            >
              <span className="animate-target my-1 me-2">أظهر المزيد</span>
              <i className="ci-chevron-down fs-lg" />
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Property;
