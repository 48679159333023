import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation("global");

  return (
    <main className="content-wrapper">
      <section className="container pt-5 mt-1 mt-sm-2 mt-md-4 mt-lg-5">
        <div
          className="text-center pb-2 pb-sm-3 pb-lg-0 pb-xxl-2 mx-auto mb-4 mb-lg-5"
          style={{
            maxWidth: "650px",
          }}
        >
          <h1 className="text-body fs-xs fw-medium text-uppercase mb-sm-4">
            مرحباً بكم في توب ديكور
          </h1>
          <h2 className="h1 mb-0">
            نحن نؤمن أن الديكور الجيد يجب أن يكون متاحاً للجميع
          </h2>
        </div>
      </section>
      <section className="container pt-5 mt-1 mt-sm-3 mt-md-4 mt-lg-5 mb-4">
        <div className="row row-cols-1 row-cols-md-3 gy-3 gy-sm-4 gx-2 gx-lg-4">
          <div className="col text-center">
            <svg
              className="d-block text-dark-emphasis mx-auto mb-3 mb-lg-4"
              fill="currentColor"
              height="64"
              viewBox="0 0 64 64"
              width="64"
              xmlns="http://www.w3.org/2000/svg"
            ></svg>
            <h3 className="h5">صديق للبيئة</h3>
            <p className="fs-sm px-5 mb-md-0">
              زين مساحتك بديكور صديق للبيئة مصنوع من مواد صديقة للبيئة وآمنة.
            </p>
          </div>
          <div className="col text-center">
            <svg
              className="d-block text-dark-emphasis mx-auto mb-3 mb-lg-4"
              fill="currentColor"
              height="64"
              viewBox="0 0 64 64"
              width="64"
              xmlns="http://www.w3.org/2000/svg"
            ></svg>
            <h3 className="h5">جودة لا مثيل لها</h3>
            <p className="fs-sm px-5 mb-md-0">
              نختار المواد الخام من أفضل المصنّعين، لذا فإن الأثاث والديكور
              لدينا يتميزان بأعلى مستويات الجودة بأفضل الأسعار.
            </p>
          </div>
          <div className="col text-center">
            <svg
              className="d-block text-dark-emphasis mx-auto mb-3 mb-lg-4"
              fill="currentColor"
              height="64"
              viewBox="0 0 64 64"
              width="64"
              xmlns="http://www.w3.org/2000/svg"
            ></svg>
            <h3 className="h5">توصيل إلى باب منزلك</h3>
            <p className="fs-sm px-5 mb-md-0">
              سنقوم بتوصيل أثاثك إلى باب منزلك في أي مكان في العالم. إذا لم تكن
              راضياً بنسبة 100%، دعنا نعرف خلال 30 يوماً وسنحل المشكلة.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-dark py-5 mt-n2 mt-sm-0" data-bs-theme="dark">
        <div className="container pt-1 pt-sm-2 pt-md-3 pb-md-2 pt-lg-4 pb-lg-3 pb-xl-4 pt-xl-5 pb-xxl-5 mt-xxl-3">
          <div className="row">
            <div className="col-lg-3 d-flex flex-column flex-sm-row flex-lg-column align-items-center align-items-lg-start pb-3 mb-3 mb-md-4">
              <h2 className="h1 text-center text-sm-start mb-sm-0 mb-lg-5 me-sm-4 me-lg-0">
                ثلاث ميزات رئيسية
              </h2>
              <a className="btn btn-lg btn-outline-light rounded-pill ms-sm-auto ms-lg-0">
                عرض الكتالوج
              </a>
            </div>
            <div className="col-lg-9">
              <div className="overflow-auto" data-simplebar="">
                <div className="d-flex gap-4">
                  <div
                    className="w-100"
                    style={{
                      minWidth: "230px",
                    }}
                  >
                    <img
                      alt="صورة"
                      className="d-block rounded-4 mb-4"
                      src="assets/img/about/v2/feature01.jpg"
                    />
                    <h3 className="h5 pb-1 mb-2">التركيز على العملاء</h3>
                    <p className="text-body fs-sm mb-4">
                      نحن نولي كل عميل أكبر قدر ممكن من الاهتمام والوقت لفهم
                      رغباتهم والحصول على النتيجة المثالية التي ترضي العميل
                      بالكامل.
                    </p>
                  </div>
                  <div
                    className="w-100"
                    style={{
                      minWidth: "230px",
                    }}
                  >
                    <img
                      alt="صورة"
                      className="d-block rounded-4 mb-4"
                      src="assets/img/about/v2/feature02.jpg"
                    />
                    <h3 className="h5 pb-1 mb-2">الاهتمام بالتفاصيل</h3>
                    <p className="text-body fs-sm mb-4">
                      نحن نراقب بعناية كل شيء - من توريد واختيار الخشب عالي
                      الجودة إلى أدق الأعمال خلال تشطيب الديكور.
                    </p>
                  </div>
                  <div
                    className="w-100"
                    style={{
                      minWidth: "230px",
                    }}
                  >
                    <img
                      alt="صورة"
                      className="d-block rounded-4 mb-4"
                      src="assets/img/about/v2/feature03.jpg"
                    />
                    <h3 className="h5 pb-1 mb-2"> السمعة</h3>
                    <p className="text-body fs-sm mb-4">
                      نحن نتحمل المسؤولية عن النزاهة والجودة والالتزام بالمواعيد
                      في إنتاج الطلبات، لأن السمعة المهنية للشركة أمر بالغ
                      الأهمية.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container pt-1 pt-sm-2 pt-md-3 mb-4 pt-lg-4 pt-xl-5 mt-xxl-3">
        <div className="row row-cols-1 row-cols-md-2 g-0 overflow-hidden rounded-5 mt-5">
          <div className="col position-relative">
            <div className="ratio ratio-4x3" />
            <img
              alt="صورة"
              className="position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
              src="assets/img/blog/grid/v2/video01.jpg"
            />
            <div className="position-absolute start-0 bottom-0 d-flex align-items-end w-100 h-100 z-2 p-4">
              <a
                className="btn btn-lg btn-light rounded-pill m-md-2"
                data-gallery="map"
                data-glightbox="width: 100vw; height: 100vh;"
                href="https://maps.app.goo.gl/9CdMJ8cTaRGu6h3A9"
              >
                <i className="ci-map fs-lg ms-n1 me-2" />
                عرض على الخريطة
              </a>
            </div>
          </div>
          <div className="col bg-body-tertiary order-md-1 py-5 px-4 px-xl-5">
            <div className="py-md-4 py-lg-5 px-md-4 px-lg-5">
              <h1 className="pb-2 pb-sm-3 pb-lg-0 mb-md-4 mb-lg-5">مركزنا</h1>
              <ul className="list-unstyled pb-sm-2 mb-0">
                <li className="d-flex">
                  <i className="ci-map-pin fs-lg mt-1" />
                  <span className="ps-2 ms-1">
                    صلاح الدين / تكريت / شارع الأربعين <br />
                  </span>
                </li>
                <li className="nav animate-underline">
                  <i className="ci-phone fs-lg mt-1 pe-2 me-1" />
                  <a
                    className="nav-link animate-target fs-base fw-normal p-0"
                    href="tel:+9647838111981"
                  >
                    +964 783 811 1981
                  </a>
                </li>
                <li className="nav animate-underline">
                  <i className="ci-mail fs-lg mt-1 pe-2 me-1" />
                  <a
                    className="nav-link animate-target fs-base fw-normal p-0"
                    href="mailto:info@topdecor-tk.com"
                  >
                    info@topdecor-tk.com
                  </a>
                </li>
              </ul>
              <hr />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default About;
