import React from "react";

const Contact = () => {
  return (
    <main className="content-wrapper">
      <div className="container py-5 mb-2 mb-sm-3 mb-md-4 mb-lg-5 mt-lg-3 mt-xl-4">
        {/* Page title */}
        <h1 className="text-center">اتصل بنا</h1>
        <p className="text-center pb-2 pb-sm-3">
          املأ النموذج أدناه وسنرد عليك في غضون 24 ساعة
        </p>
        {/* Form + Image */}
        <section className="row row-cols-1 row-cols-md-2 g-0 overflow-hidden rounded-5">
          {/* Contact form */}
          <div className="col bg-body-tertiary py-5 px-4 px-xl-5">
            <form
              className="needs-validation py-md-2 px-md-1 px-lg-3 mx-lg-3"
              noValidate=""
            >
              <div className="position-relative mb-4">
                <label htmlFor="name" className="form-label">
                  الاسم *
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg rounded-pill"
                  id="name"
                  required=""
                />
                <div className="invalid-tooltip bg-transparent z-0 py-0 ps-3">
                  أدخل اسمك!
                </div>
              </div>
              <div className="position-relative mb-4">
                <label htmlFor="email" className="form-label">
                  البريد الإلكتروني *
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg rounded-pill"
                  id="email"
                  required=""
                />
                <div className="invalid-tooltip bg-transparent z-0 py-0 ps-3">
                  أدخل بريدك الإلكتروني!
                </div>
              </div>
              <div className="position-relative mb-4">
                <label className="form-label">الموضوع *</label>
                <select
                  className="form-select form-select-lg rounded-pill"
                  data-select='{
                    "classNames": {
                      "containerInner": "form-select form-select-lg rounded-pill"
                    }
                  }'
                  required=""
                >
                  <option value="">اختر الموضوع</option>
                  <option value="General inquiry">استفسار عام</option>
                  <option value="Order status">حالة الطلب</option>
                  <option value="Product information">معلومات المنتج</option>
                  <option value="Technical support">الدعم الفني</option>
                  <option value="Website feedback">ملاحظات الموقع</option>
                  <option value="Account assistance">مساعدة الحساب</option>
                  <option value="Security concerns">مخاوف أمنية</option>
                </select>
                <div className="invalid-tooltip bg-transparent z-0 py-0 ps-3">
                  اختر موضوع رسالتك!
                </div>
              </div>
              <div className="position-relative mb-4">
                <label htmlFor="message" className="form-label">
                  الرسالة *
                </label>
                <textarea
                  className="form-control form-control-lg rounded-6"
                  id="message"
                  rows={5}
                  required=""
                  defaultValue={""}
                />
                <div className="invalid-tooltip bg-transparent z-0 py-0 ps-3">
                  اكتب رسالتك!
                </div>
              </div>
              <div className="pt-2">
                <button
                  type="submit"
                  className="btn btn-lg btn-dark rounded-pill"
                >
                  إرسال الرسالة
                </button>
              </div>
            </form>
          </div>
          {/* Image */}
          <div className="col position-relative">
            <img
              src="assets/img/contact/form-image.jpg"
              className="position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
              alt="صورة"
            />
          </div>
        </section>
        {/* Contacts */}
        <section className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4 pt-5 pb-3 pb-md-4 pb-lg-3 mt-lg-0 mt-xxl-4">
          <div className="col text-center pt-1 pt-sm-2 pt-md-3">
            <div className="position-relative d-inline-block bg-body-tertiary text-dark-emphasis fs-xl rounded-circle p-4 mb-3">
              <i className="ci-phone-outgoing position-absolute top-50 start-50 translate-middle" />
            </div>
            <h3 className="h6">اتصل بنا مباشرة</h3>
            <ul className="list-unstyled m-0">
              <li className="nav animate-underline justify-content-center">
                العملاء:
                <a
                  className="nav-link animate-target fs-base ms-1 p-0"
                  href="tel:+9647838111981"
                >
                  +964&nbsp;783&nbsp;811&nbsp;1981
                </a>
              </li>
            </ul>
          </div>
          <div className="col text-center pt-1 pt-sm-2 pt-md-3">
            <div className="position-relative d-inline-block bg-body-tertiary text-dark-emphasis fs-xl rounded-circle p-4 mb-3">
              <i className="ci-mail position-absolute top-50 start-50 translate-middle" />
            </div>
            <h3 className="h6">إرسال رسالة</h3>
            <ul className="list-unstyled m-0">
              <li className="nav animate-underline justify-content-center">
                العملاء:
                <a
                  className="nav-link animate-target fs-base ms-1 p-0"
                  href="mailto:info@topdecor-tk.com"
                >
                  info@topdecor-tk.com
                </a>
              </li>
            </ul>
          </div>
          <div className="col text-center pt-1 pt-sm-2 pt-md-3">
            <div className="position-relative d-inline-block bg-body-tertiary text-dark-emphasis fs-xl rounded-circle p-4 mb-3">
              <i className="ci-map-pin position-absolute top-50 start-50 translate-middle" />
            </div>
            <h3 className="h6">موقع المتجر</h3>
            <ul className="list-unstyled m-0">
              <li>تكريت، العراق</li>
              <li>شارع الأربعين، صلاح الدين</li>
            </ul>
          </div>
          <div className="col text-center pt-1 pt-sm-2 pt-md-3">
            <div className="position-relative d-inline-block bg-body-tertiary text-dark-emphasis fs-xl rounded-circle p-4 mb-3">
              <i className="ci-clock position-absolute top-50 start-50 translate-middle" />
            </div>
            <h3 className="h6">ساعات العمل</h3>
            <ul className="list-unstyled m-0">
              <li>جميع أيام الأسبوع 8:00 - 18:00</li>
              <li>الجمعة - مغلق</li>
            </ul>
          </div>
        </section>
        <hr className="my-lg-5" />
      </div>
    </main>
  );
};

export default Contact;
