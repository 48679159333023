import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { animateScroll as scroll } from "react-scroll";
import Logo from "../images/logo.png";
import { addItem } from "../../context/actions/postaction";

const ShopingCard = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { projects } = useSelector((state) => state.projects);
  const totalItems = cart.reduce((total, item) => total + item.count, 0);

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };

  const totalPrice = cart.reduce((total, item) => {
    const product = projects.find((product) => product.id === item.id);
    return (
      total +
      (product.in_discount == 1
        ? product.discount_price
        : product.product_price) *
        item.count
    );
  }, 0);

  const handleIncrement = (id) => {
    dispatch({
      type: "add",
      id,
      colorId: 2,
    });
  };

  const handleDecrement = (id) => {
    dispatch({ type: "remove", id, colorId: 2 });
  };

  const handleRemove = (id) => {
    dispatch({ type: "delete", id, colorId: 2 });
  };

  const generateRandomCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";
    const codeLength = 8;

    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }

    return code;
  };

  const [Name, setName] = useState("");
  const [Note, setNote] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [Address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [error, setError] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!Name || !Note || !Email || !Phone || !Address || !city) {
      setError("جميع الحقول مطلوبة");
      scrollToTop();
      return;
    }

    const orderCode = generateRandomCode();
    const itemData = {
      items: cart.map((item) => ({
        name: Name,
        email: Email,
        phone: Phone,
        note: Note,
        address: Address,
        city: city,
        id: parseInt(item.id),
        piece: item.count,
        order_code: orderCode,
        color_id: item.colorId,
      })),
    };

    try {
      const response = await dispatch(addItem(itemData.items));
      if (response.status === 200) {
        setResponseStatus("success");
        setName("");
        setNote("");
        setEmail("");
        setAddress("");
        setCity("");
        setPhone("");
        navigate(`/confirmed/${orderCode}`);
        scrollToTop();
      } else {
        setResponseStatus("error");
      }
    } catch (error) {
      setResponseStatus("error");
    }

    dispatch({ type: "clearCart" });
  };

  return (
    <>
      <div
        aria-labelledby="shoppingCartLabel"
        className="offcanvas offcanvas-end pb-sm-2 px-sm-2"
        id="shoppingCart"
        style={{
          width: "500px",
        }}
      >
        <div className="offcanvas-header py-3 pt-lg-4 justify-content-between">
          <img
            style={{ width: "30%", height: "100%" }}
            className=""
            src={Logo}
            alt="Logo"
          />
          <button
            aria-label="Close"
            className="btn "
            data-bs-dismiss="offcanvas"
            type="button"
          >
            <i className="ci-close" />
          </button>
        </div>
        {/* Items */}
        <div className="offcanvas-body d-flex flex-column gap-4 pt-2">
          {/* Item */}
          {cart.map((item, key) => {
            const itemInCart = projects.find((e) => e.id === item.id);
            return (
              <div key={key} className="d-flex align-items-center">
                <a className="flex-shrink-0" href="#!">
                  <img
                    src={`https://top-decor.alayadi.site/product_imgs/${itemInCart.images[0].product_photo}`}
                    className="bg-body-tertiary rounded"
                    width={110}
                    alt="Thumbnail"
                  />
                </a>
                <div className="w-100 min-w-0 ps-3">
                  <h5 className="d-flex animate-underline mb-2">
                    <a
                      className="d-block fs-sm fw-medium text-truncate animate-target"
                      href="#!"
                    >
                      {itemInCart.product_title_ar}
                    </a>
                  </h5>
                  <div className="h6 pb-1 mb-2">
                    {itemInCart.product_price} د.ع
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="count-input rounded-2">
                      <button
                        type="button"
                        className="btn btn-icon btn-sm"
                        onClick={() => handleDecrement(item.id)}
                        aria-label="Decrement quantity"
                      >
                        <i className="ci-minus" />
                      </button>
                      <input
                        type="number"
                        className="form-control form-control-sm"
                        value={item.count}
                        readOnly
                      />
                      <button
                        type="button"
                        className="btn btn-icon btn-sm"
                        onClick={() => handleIncrement(item.id)}
                        aria-label="Increment quantity"
                      >
                        <i className="ci-plus" />
                      </button>
                    </div>
                    <button
                      type="button"
                      className="btn-close fs-sm"
                      data-bs-toggle="tooltip"
                      data-bs-custom-class="tooltip-sm"
                      data-bs-title="Remove"
                      aria-label="Remove from cart"
                      onClick={() => handleRemove(item.id)}
                    />
                  </div>
                </div>
                <button
                  onClick={() => handleRemove(item.id)}
                  className="btn "
                  type="button"
                >
                  <i className="ci-close" />
                </button>
              </div>
            );
          })}
        </div>

        {/* Footer */}
        <div className="offcanvas-header flex-column align-items-start">
          <div className="d-flex align-items-center justify-content-between w-100 mb-3 mb-md-4">
            <span className="text-light-emphasis">المجموع:</span>
            <span className="h6 mb-0">{totalPrice}</span>
          </div>
          <div
            className="d-flex w-100 gap-3"
            data-bs-toggle="modal"
            data-bs-target="#modalId"
          >
            <a className="btn btn-lg btn-dark w-100">أكمال الطلب</a>
          </div>
        </div>
      </div>
      <div dir="rtl" className="modal fade " id="modalId" role="dialog">
        <div className="modal-dialog modal-fullscreen" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">إكمال الطلب</h5>
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="needs-validation" noValidate="">
                <div className="row">
                  <div className="col-md-4 position-relative mb-4">
                    <label
                      htmlFor="validationTooltipt01"
                      className="form-label"
                    >
                      الاسم الأول
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationTooltipt01"
                      placeholder="الاسم الأول"
                      value={Name}
                      onChange={(e) => setName(e.target.value)}
                      required=""
                    />
                    <div className="valid-tooltip">يبدو جيدا!</div>
                  </div>
                  <div className="col-md-4 position-relative mb-4">
                    <label
                      htmlFor="validationTooltipt02"
                      className="form-label"
                    >
                      البريد الإلكتروني
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="validationTooltipt02"
                      placeholder="البريد الإلكتروني"
                      value={Email}
                      onChange={(e) => setEmail(e.target.value)}
                      required=""
                    />
                    <div className="valid-tooltip">يبدو جيدا!</div>
                  </div>
                  <div className="col-md-4 position-relative mb-4">
                    <label
                      htmlFor="validationTooltiptUsername"
                      className="form-label"
                    >
                      رقم الهاتف
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="validationTooltiptUsername"
                      placeholder="رقم الهاتف"
                      value={Phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required=""
                    />
                    <div className="invalid-tooltip">
                      يرجى إدخال رقم هاتف صحيح.
                    </div>
                    <div className="valid-tooltip">يبدو جيدا!</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 position-relative mb-4">
                    <label
                      htmlFor="validationTooltipt03"
                      className="form-label"
                    >
                      العنوان
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationTooltipt03"
                      placeholder="العنوان"
                      value={Address}
                      onChange={(e) => setAddress(e.target.value)}
                      required=""
                    />
                    <div className="invalid-tooltip">
                      يرجى إدخال عنوان صحيح.
                    </div>
                    <div className="valid-tooltip">يبدو جيدا!</div>
                  </div>
                  <div className="col-md-6 position-relative mb-4">
                    <label
                      htmlFor="validationTooltipt04"
                      className="form-label"
                    >
                      المدينة
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationTooltipt04"
                      placeholder="المدينة"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      required=""
                    />
                    <div className="invalid-tooltip">
                      يرجى إدخال مدينة صحيحة.
                    </div>
                    <div className="valid-tooltip">يبدو جيدا!</div>
                  </div>
                </div>
                <div className="position-relative mb-4">
                  <label htmlFor="validationTooltipt05" className="form-label">
                    ملاحظة
                  </label>
                  <textarea
                    className="form-control"
                    id="validationTooltipt05"
                    placeholder="ملاحظة"
                    value={Note}
                    onChange={(e) => setNote(e.target.value)}
                    required=""
                  />
                  <div className="invalid-tooltip">يرجى إدخال ملاحظة.</div>
                  <div className="valid-tooltip">يبدو جيدا!</div>
                </div>
                <button
                  data-bs-dismiss="modal"
                  className="btn btn-primary"
                  type="button"
                  onClick={handleSubmit}
                >
                  إتمام الطلب
                </button>
              </form>
            </div>
            <div className="modal-footer flex-column flex-sm-row align-items-stretch">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                إغلاق
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopingCard;
